<ng-container [formGroup]="formGroup">
  <icon-box icon="monochromeUser"></icon-box>
  <app-select
    class="flex flex-1 border-transparent"
    [readonly]="readonly"
    [options]="options"
    [underLabel]="underLabelText"
    (change)="onChange($event)"
    [clearable]="false"
    [sortByLabel]="true"
    [for]="for"
  >
  </app-select>
</ng-container>
