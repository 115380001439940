import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { SelectComponent } from '../select.component';
import { TranslatedValuePipe } from 'src/app/pipes/translated-value.pipe';
import { IconBoxComponent } from 'src/app/components/_elements/icon-box/icon-box.component';

@Component({
  selector: 'owner-select',
  templateUrl: './owner-select.component.html',
  styleUrls: ['./owner-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SelectComponent, ReactiveFormsModule, IconBoxComponent],
  providers: [TranslatedValuePipe],
})
export class OwnerSelectComponent extends SelectComponent {
  @Input()
  public underLabelText: string = 'common.label.owner';
}
